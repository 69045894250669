<template>
  <div
    class="content-component"
    @click="handleClearSelection"
    style="width: 100%;"
  >
    <el-button
      :disabled="!can('content.' + additionalValidateRoute + '.write')"
      class="createBtn"
      @click.stop="createContentItem"
      >Create Dynamic Formtype</el-button
    >
    <div style="width: 100%">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :content-api="contentAPI"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar v-loading="loading" :native="true">
          <div class="contentCardsContainer">
            <el-row
              type="flex"
              justify="start"
              class="list-cards"
              v-if="dynamicFormTypes.length"
              v-show="can('content.' + additionalValidateRoute + '.read')"
            >
              <ContentCard
                v-for="(dynamicFormType, index) in dynamicFormTypes"
                :key="'_content_dynamic_form_type_' + index"
                :draggable-data="{
                  content_id: selectedItems.length
                    ? selectedIds
                    : [dynamicFormType[primaryKey]],
                  content_type: contentType
                }"
                :allow-multi-select="true"
                :card-class="{ selected: selectedItemIndex === index }"
                icon="icon-custom-form.svg"
                :card-text="dynamicFormType.dft_name"
                @delete="
                  handleDelete(
                    dynamicFormType,
                    'DynamicFormType',
                    dynamicFormType.dynamic_form_type_id
                  )
                "
                @select="handleSelect(index, dynamicFormType)"
                @edit="handleEdit(index, dynamicFormType)"
                @checked-content="handleSelectMultiple($event)(dynamicFormType)"
                :total-items-selected="selectedItems.length"
              ></ContentCard>
            </el-row>
            <el-row
              v-else-if="!loading"
              style="padding-top: 100px;"
              type="flex"
              justify="center"
            >
              No Dynamic Forms
            </el-row>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import ContentCard from "@/views/build/content/mixins/ContentCard";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { EventBus } from "@/EventBus";
import _ from "lodash";

export default {
  name: "DynamicFormTypesList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    ContentCard
  },
  selectedItemIndex: {
    required: false,
    default: null
  },
  data() {
    return {
      additionalValidateRoute: "dynamic-forms",
      sortByColumns: ["dft_name", "dynamic_form_type_id"],
      isContent: true,
      forceDeleteEventName: "initiate-force-delete-DynamicFormType",
      safeDeleteEventName: "initiate-safe-delete-DynamicFormType",
      viewMode: "allow_read"
    };
  },

  computed: {
    ...mapState("dynamicFormTypes", {
      dynamicFormTypes: state => state.dynamicformtypes,
      loading: state => state.loading
    }),
    selectedIds() {
      return _.map(this.selectedItems, this.primaryKey);
    }
  },

  methods: {
    ...mapActions("dynamicFormTypes", {
      contentAPI: "getDynamicFormTypes",
      deleteContentMethod: "deleteDynamicFormType",
      undoDeleteContent: "undoDeleteDynamicFormType",
      safeDeleteContent: "safeDeleteDynamicFormType"
    })
  },
  created() {
    console.log("created");
    EventBus.$on(this.forceDeleteEventName, ({ content }) => {
      console.log("formtype");
      this.initiateDelete(content);
    });

    EventBus.$on(this.safeDeleteEventName, ({ content }) => {
      this.initiateSafeDelete(content);
    });
  },
  beforeDestroy() {
    EventBus.$off(this.forceDeleteEventName);
    EventBus.$off(this.safeDeleteEventName);
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: #4db3f6 !default;
$content-theme-hover-color: dodgerblue !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";

.advancedBtn {
  margin-left: 20px;
  height: 44px;
  width: 30px;
  border-color: #f5f5f8;
  color: #a0a8b5;
  &:hover {
    background-color: transparent;
    border-color: $content-theme-color;
    ::v-deep svg {
      fill: $content-theme-hover-color;
      path {
        fill: $content-theme-hover-color;
      }
    }
  }
  &.is-disabled:hover,
  &.is-disabled {
    color: #a0a8b5;
    border-color: #f5f5f8;
    ::v-deep svg {
      fill: #a0a8b5;
      path {
        fill: #a0a8b5;
      }
    }
  }
}

.custom-icon {
  font-size: 1.5rem;
  color: #a0a8b5;
  padding: 0 0 30px 20px;
}

.archived {
  color: $content-theme-hover-color;
}

.custom-header-text {
  color: #a0a8b5;
  font-size: 17px;
}
</style>
