<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-if="multipleItemsSelectedCount">
      <p>
        Selected
        <el-tag>
          <strong>{{ multipleItemsSelectedCount }}</strong>
        </el-tag>
        items.
      </p>
    </div>
    <div v-else>
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">{{ contentForm.dft_name }}</div>
      <div class="titles">{{ __("Description") }}</div>
      <div class="details">{{ contentForm.dft_description }}</div>
    </div>

    <div style="margin-top: 20px;">
      <el-dropdown
        @command="command => handleAction(command)"
        placement="bottom-start"
      >
        <el-button plain>{{ __("Actions") }}</el-button>
        <el-dropdown-menu slot="dropdown" style="width: 200px;">
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount && !isArchivedStatus"
            command="edit"
            :disabled="!can('content.dynamic-forms.write')"
          >
            <img
              class="action-icon"
              :src="require('@/assets/icons/icon-edit.svg')"
            />
            <span>{{ __("Edit") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            command="move"
            :disabled="!can('content.dynamic-forms.write')"
          >
            <img
              class="action-icon"
              :src="require('@/assets/icons/move-phone-numbers.svg')"
            />
            <span>{{ __("Move to folder") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="check_in_use"
            :disabled="!!contentFormInfo.is_refreshing"
          >
            <img
              class="action-icon"
              :src="require('@/assets/icons/move-phone-numbers.svg')"
            />
            <span>{{ __("Check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount"
            command="refresh"
          >
            <img
              class="action-icon"
              :src="require('@/assets/icons/move-phone-numbers.svg')"
            />
            <span>{{ __("Refresh check in use") }}</span>
          </el-dropdown-item>
          <el-dropdown-item
            v-show="!multipleItemsSelectedCount && isArchivedStatus"
            :disabled="!can('content.dynamic-forms.write')"
            command="restore"
          >
            <i
              class="action-icon el-icon-time"
              style="padding-right: 15px;"
            ></i>
            <span>{{ __("Restore") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
      v-loading="isSubmitting"
      :modal="false"
    >
      <el-scrollbar :native="false">
        <div class="max-vh">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
            :disabled="isArchivedStatus"
          >
            <el-row type="flex" style="padding-top: 20px">
              <el-col :span="6"></el-col>
              <el-col :span="18">
                <el-row type="flex">
                  <h2
                    class="custom-formtype-icon inf-icon inf-icon-custom-form"
                  ></h2>
                  <page-header
                    :title="__('Dynamic Formtype')"
                    :content-id="this.id"
                    style="margin-top: 33px;"
                  />
                </el-row>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="6"></el-col>
              <el-col :span="9">
                <el-form-item prop="dft_name" label="Name">
                  <el-input
                    v-model="contentForm.dft_name"
                    maxlength="100"
                    show-word-limit
                    ref="dft_name"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-tabs
                  v-model="activeTab"
                  class="tabs"
                  style="font-size: 1.2em;"
                >
                  <el-tab-pane :label="__('Form')" name="Form">
                    <el-form-item
                      prop="dft_description"
                      :label="__('Description')"
                    >
                      <el-input
                        v-model="contentForm.dft_description"
                        maxlength="200"
                        show-word-limit
                      ></el-input>
                    </el-form-item>

                    <el-form-item
                      prop="json_schema"
                      :label="__('Sample JSON Schema')"
                      class="response"
                      :required="true"
                    >
                      <span slot="label">
                        {{ __("Sample JSON Schema") }}
                        <el-popover
                          placement="top-start"
                          width="585"
                          trigger="hover"
                          content=""
                          ref="jsonSchemaTooltip"
                        >
                          <ul
                            style="padding-left: 20px; margin-top: 5px; margin-bottom: 5px"
                          >
                            <!-- eslint-disable-next-line -->
                            <li style="margin-bottom: 10px">{{ __("The Sample JSON Schema is an example of the first JSON object in the JSON source array") }}</li>
                            <!-- eslint-disable-next-line -->
                            <li style="margin-bottom: 0;">{{ __("For JSON path to match on DTMF and SPEECH, the JSON path attributes must be at the root level on the JSON object") }}</li>
                          </ul>
                          <i
                            slot="reference"
                            style="font-size: 0.938rem"
                            class="el-icon-info icon"
                          ></i>
                        </el-popover>
                      </span>
                      <json-schema-mapper
                        :json-schema="contentForm.json_schema || '{}'"
                        :json-schema-editable="true"
                        :show-edit-button="true"
                        @changeJsonSchema="updateJsonSchema"
                        @sampleJsonSchemaClicked="addJsonPath"
                      >
                      </json-schema-mapper>
                    </el-form-item>
                    <el-form-item
                      :label="__('Add Custom JSON Path')"
                      style="margin-top: 30px"
                      :error="invalidJsonPathConfigurationError"
                    >
                      <div class="customJsonPathsTable">
                        <el-table
                          fit
                          class="button-rules"
                          :data="dftOutput"
                          style="width: 100%;"
                          :show-header="false"
                        >
                          <el-table-column style="width: 100%">
                            <template slot-scope="scope">
                              <div class="custom-json-path-field">
                                <el-form-item
                                  :label="__('JSON Path')"
                                  class="custom-json-path-field-item custom-json-path-field-item--jsonpath"
                                >
                                  <el-input
                                    :placeholder="__('JSON Path')"
                                    :value="scope.row.path"
                                    @input="
                                      writeDftOutput($event)(scope.row, 'path')
                                    "
                                  ></el-input>
                                </el-form-item>
                                <el-form-item
                                  :label="__('Output Type')"
                                  class="custom-json-path-field-item custom-json-path-field-item--outputtype"
                                >
                                  <el-select
                                    :value="scope.row.outputType"
                                    style="width: 100%;"
                                    :no-data-text="
                                      __('No Output Type selected')
                                    "
                                    :placeholder="
                                      __('Select Output Type for JSON Path')
                                    "
                                    filterable
                                    clearable
                                    @change="
                                      writeDftOutput($event)(
                                        scope.row,
                                        'outputType'
                                      )
                                    "
                                  >
                                    <el-option
                                      v-for="(item,
                                      index) in getOutputTypeOptions"
                                      :label="item"
                                      :value="item"
                                      :key="`output_type_${index}`"
                                    />
                                  </el-select>
                                </el-form-item>
                                <el-form-item
                                  :label="__('Output Name')"
                                  class="custom-json-path-field-item custom-json-path-field-item--outputname"
                                >
                                  <el-input
                                    :placeholder="__('Output Name')"
                                    :value="scope.row.outputName"
                                    @input="
                                      writeDftOutput($event)(
                                        scope.row,
                                        'outputName'
                                      )
                                    "
                                    :disabled="isJsonSchemaPathOutputNameLocked"
                                    maxlength="100"
                                    show-word-limit
                                  ></el-input>
                                </el-form-item>
                                <div
                                  class="custom-json-path-field-item custom-json-path-field-item--addjsonpath"
                                >
                                  <el-button
                                    style="width:100%"
                                    type="primary"
                                    id="addDftOutput"
                                    @click="addCustomJsonPath"
                                    :disabled="
                                      !isCustomJsonPathConfigurationComplete
                                    "
                                    >{{ __("Add") }}
                                  </el-button>
                                </div>
                              </div></template
                            >
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-form-item>

                    <el-form-item
                      :label="__('Custom JSON Paths')"
                      style="margin-top: 30px"
                      prop="dft_outputs"
                    >
                      <div class="customJsonPathsTable">
                        <el-table
                          fit
                          class="button-rules"
                          :data="dftOutputs"
                          style="width: 100%;"
                          :show-header="false"
                        >
                          <el-table-column style="width: 100%">
                            <template slot-scope="scope">
                              <div class="custom-json-path-field">
                                <el-form-item
                                  :label="__('JSON Path')"
                                  class="custom-json-path-field-item custom-json-path-field-item--jsonpath"
                                >
                                  {{ scope.row.output_value }}
                                </el-form-item>

                                <el-form-item
                                  :label="__('Output Type')"
                                  :required="true"
                                  :error="
                                    errorMessage(
                                      scope.row,
                                      'output_type',
                                      __('Output Type')
                                    )
                                  "
                                  class="custom-json-path-field-item custom-json-path-field-item--outputtype"
                                >
                                  <el-select
                                    v-model="scope.row.output_type"
                                    style="width: 100%;"
                                    :no-data-text="
                                      __('No Output type selected')
                                    "
                                    :placeholder="
                                      __('Select Type for JSON Path')
                                    "
                                    filterable
                                    @change="
                                      setCustomJsonPathOutputName(scope.row)
                                    "
                                  >
                                    <el-option
                                      v-for="(item,
                                      index) in getOutputTypeOptions"
                                      :label="item"
                                      :value="item"
                                      :key="`edit_output_type_${index}`"
                                    />
                                  </el-select>
                                </el-form-item>

                                <el-form-item
                                  :label="__('Output Name')"
                                  :required="true"
                                  :error="
                                    errorMessage(
                                      scope.row,
                                      'output_name',
                                      __('Output Name')
                                    )
                                  "
                                  class="custom-json-path-field-item custom-json-path-field-item--outputname"
                                >
                                  <el-input
                                    v-model="scope.row.output_name"
                                    :disabled="
                                      isCustomJsonPathOutputNameLocked(
                                        scope.row
                                      )
                                    "
                                    maxlength="100"
                                    show-word-limit
                                    @input="triggerValidation(['dft_outputs'])"
                                  ></el-input>
                                </el-form-item>

                                <div
                                  class="custom-json-path-field-item custom-json-path-field-item--action"
                                >
                                  <span @click="removeJsonPath(scope.$index)">
                                    <i class="el-icon-circle-close"></i>
                                  </span>
                                </div>
                              </div>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-form-item>
                  </el-tab-pane>
                  <el-tab-pane
                    :label="__('Advanced ASR Settings')"
                    name="advanced_asr_settings"
                  >
                    <el-scrollbar :native="false">
                      <div style="max-height: 90vh">
                        <advanced-speech-parameters
                          :speech-complete-timeout="
                            contentForm.speech_complete_timeout
                          "
                          :speech-incomplete-timeout="
                            contentForm.speech_incomplete_timeout
                          "
                          :no-input-timeout="contentForm.no_input_timeout"
                          :speech-start-timeout="
                            contentForm.speech_start_timeout
                          "
                          :inter-result-timeout="
                            contentForm.inter_result_timeout
                          "
                          :barge-in-sensitivity="
                            contentForm.barge_in_sensitivity
                          "
                          :auto-punctuation="contentForm.auto_punctuation"
                          :profanity-filter="contentForm.profanity_filter"
                          :single-utterance="contentForm.single_utterance"
                          @update-nlp-parameter="updateNlpParameters"
                        >
                        </advanced-speech-parameters>
                      </div>
                    </el-scrollbar>
                  </el-tab-pane>
                </el-tabs>
              </el-col>
            </el-row>
          </el-form>
          <div
            slot="footer"
            style="display: flex;margin-left: 25%;margin-bottom: 20px; margin-top: 30px;"
          >
            <save-button
              v-if="!isArchivedStatus"
              :disabled="!contentUpdated || !can('content.dynamic-forms.write')"
              type="primary"
              @click="confirmSave(id)"
              class="submitBtn"
              :primaryKey="id"
              variant="CreateUpdate"
            />
            <el-button @click="handleFormCancel" class="cancelBtn">{{
              __("Cancel")
            }}</el-button>
          </div>
        </div>
      </el-scrollbar>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { EventBus } from "@/EventBus";
import { mapState, mapActions } from "vuex";
import PageHeader from "@/components/PageHeader";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { checkUniqueName } from "@/api/dynamicformtypes";
import JsonSchemaMapper from "@/views/build/callflow/components/node-type-forms/components/JsonSchemaMapper";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import AdvancedSpeechParameters from "@/components/AdvancedSpeechParameters.vue";
import { prettifyJsonPath } from "@/utils/transformers";
import { jsonPathValue } from "@/utils/canvas";
import SaveButton from "@/components/SaveButton";

const jp = require("jsonpath");

const ruleConfigurer = {
  output_value: "",
  output_name: "",
  output_type: ""
};

const OUTPUT_TYPE_SPEECH = "SPEECH";
const OUTPUT_TYPE_DTMF = "DTMF";
const OUTPUT_TYPE_RETURN_COLUMN = "RETURN_COLUMN";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    JsonSchemaMapper,
    PageHeader,
    //   JsonPathToString,
    AdvancedSpeechParameters,
    SaveButton
  },
  data() {
    let validateFtName = async (rule, value, callback) => {
      try {
        // create and update scenario
        if (this.performUniqueNameCheck(value)) {
          this.isChecking = true;
          const res = await checkUniqueName(value);
          this.isChecking = false;
          if (res.data.found) {
            callback(
              new Error(
                // eslint-disable-next-line
                    __("This Dynamic Formtype name is reserved or already exists")
              )
            );
          } else {
            callback();
          }
        }
      } catch (e) {
        console.log(e);
        this.isChecking = false;
      }
    };

    let validateJsonSchema = (rule, value, callback) => {
      try {
        if (value) {
          let parsedJson = JSON.parse(value);
          if (_.isEmpty(parsedJson)) {
            callback(new Error(__("Please enter a valid Sample JSON Schema")));
          } else {
            callback();
          }
        } else {
          callback();
        }
      } catch (e) {
        callback(new Error(__("Please enter a valid Sample JSON Schema")));
      }
    };

    let validateDftOutputs = (rule, value, callback) => {
      let self = this;

      let isAllFieldsFilled = _.every(value, dftOutput => {
        if (
          dftOutput.output_type !== "" &&
          dftOutput.output_value !== "" &&
          dftOutput.output_name !== ""
        ) {
          return true;
        }

        return false;
      });

      // must have dtmf or speech custom json path
      let isMinRequiredJsonPathValid = _.some(value, dftOutput => {
        if (
          dftOutput.output_type === OUTPUT_TYPE_SPEECH ||
          dftOutput.output_type === OUTPUT_TYPE_DTMF
        ) {
          return true;
        }

        return false;
      });

      // check reserved name not being used
      let reservedNamesValid = !_.some(value, dftOutput => {
        if (
          self.findDuplicateReservedOutputName(
            dftOutput.output_name,
            OUTPUT_TYPE_DTMF
          )
        ) {
          return true;
        }
        if (
          self.findDuplicateReservedOutputName(
            dftOutput.output_name,
            OUTPUT_TYPE_SPEECH
          )
        ) {
          return true;
        }

        return false;
      });

      // no dupes
      let duplicateNamesValid = !_.some(value, dftOutput => {
        if (self.findDuplicateOutputName(dftOutput.output_name)) {
          return true;
        }

        return false;
      });

      if (!isAllFieldsFilled) {
        callback(__("Please fill in a required field"));
      } else if (this.validatingOnSubmit && !isMinRequiredJsonPathValid) {
        callback(
          // eslint-disable-next-line
          __("Dynamic form type must have at least one DTMF or SPEECH json path")
        );
      } else if (!reservedNamesValid) {
        callback(
          // eslint-disable-next-line
          __("Custom Json Path output name conflicts with reserved output names for DTMF and SPEECH, please use another")
        );
      } else if (!duplicateNamesValid) {
        callback(
          // eslint-disable-next-line
          __("Duplicate Custom Json Path output name detected, please use another")
        );
      } else {
        callback();
      }
    };

    return {
      ruleConfigurer: _.cloneDeep(ruleConfigurer),
      rules: {
        dft_outputs: [{ validator: validateDftOutputs, trigger: "none" }],
        dft_name: [
          { required: true, message: "Name is required", trigger: "blur" },
          { validator: validateFtName, trigger: "blur" }
        ],
        json_schema: [
          {
            required: true,
            message: "Sample JSON Schema is required",
            trigger: "blur"
          },
          {
            validator: validateJsonSchema,
            trigger: "blur"
          }
        ]
      },

      isSubmitting: false,
      publishFormTypeStatus: "disable",
      publishFormTypeConfirmed: "disabled",
      additionalValidateRoute: "dynamic-forms",
      isChecking: false,
      isContent: true,
      viewMode: "allow_read",
      activeTab: "Form",
      jsonPathValue,
      prettify: prettifyJsonPath,
      jsonSchemaPath: "",
      jsonSchemaPathOutputType: "",
      jsonSchemaPathOutputName: "",
      validatingOnSubmit: false,
      dftOutput: []
    };
  },

  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),
    ...mapState("dataStores", {
      dataStores: state => state.dataStores,
      dataStoresLoading: state => state.loading
    }),

    dftOutputs: {
      get: function() {
        const dftOutputs = this.contentForm.dft_outputs;
        return _.isEmpty(dftOutputs) ? [] : dftOutputs;
      },
      set: function(val) {
        let updatedVal = _.cloneDeep(val);
        if (!_.isEqual(this.dftOutputs, updatedVal) || _.isEmpty(updatedVal)) {
          this.$set(this.contentForm, "dft_outputs", updatedVal);
        }
      }
    },
    /**
     * check json schema has a value
     * @returns {boolean}
     */
    isJsonSchemaEmpty() {
      return _.isEmpty(this.contentForm.json_schema);
    },

    /**
     * get the output type options
     * @returns {string[]}
     */
    getOutputTypeOptions() {
      let outputTypeOptions = [OUTPUT_TYPE_SPEECH, OUTPUT_TYPE_RETURN_COLUMN];

      if (!this.hasDtmfDftOutput) {
        outputTypeOptions.push(OUTPUT_TYPE_DTMF);
      }

      return outputTypeOptions;
    },

    /**
     * get all the possible paths in the json schema
     * @returns {*[]}
     */
    getJsonSchemaResponsePaths() {
      let paths = [];
      let jsonPaths = [];
      if (!this.isJsonSchemaEmpty) {
        paths = jp.paths(JSON.parse(this.contentForm.json_schema), "$..*");
        if (_.isArray(paths)) {
          jsonPaths = _.map(paths, path => {
            let bracketPath = "";
            _.each(path, (subPath, index) => {
              if (subPath === "$" && index === 0) {
                bracketPath += `${subPath}`;
              } else {
                bracketPath += _.isInteger(subPath)
                  ? `[${subPath}]`
                  : `['${subPath}']`;
              }
            });

            return bracketPath;
          });
        }
      }
      return jsonPaths;
    },

    /**
     * check for the output name to be locked
     */
    isJsonSchemaPathOutputNameLocked() {
      if (this.jsonSchemaPathOutputType === OUTPUT_TYPE_RETURN_COLUMN) {
        return false;
      }

      return true;
    },

    /**
     * has an existing dft output
     * @returns {boolean}
     */
    hasDtmfDftOutput() {
      return this.findDftOutput(OUTPUT_TYPE_DTMF, OUTPUT_TYPE_DTMF) !== "";
    },

    /**
     * configuration complete, then can add custom json path
     * @returns {boolean}
     */
    isCustomJsonPathConfigurationComplete() {
      return (
        this.isValidJsonSchemaPath() &&
        this.jsonSchemaPathOutputType !== "" &&
        this.isValidJsonSchemaPathOutputName()
      );
    },

    /**
     * output invalid json path message
     * @returns {string}
     */
    invalidJsonPathConfigurationError() {
      let errorMessage = "";

      if (!_.isEmpty(this.jsonSchemaPath) && !this.isValidJsonSchemaPath()) {
        errorMessage += ", has invalid JSON path";
      }
      if (
        !_.isEmpty(this.jsonSchemaPathOutputName) &&
        this.hasJsonSchemaPathDefaultOutputNameConflict()
      ) {
        errorMessage +=
          ", output name conflicts with default DTMF and Speech mappings, please choose another";
      } else if (
        !_.isEmpty(this.jsonSchemaPathOutputName) &&
        this.hasExistingDftOutputName(this.jsonSchemaPathOutputName)
      ) {
        errorMessage +=
          ", output name conflicts with existing output name for Custom JSON Paths, please choose another";
      }

      if (errorMessage !== "") {
        errorMessage = `Json Path Configuration Incorrect ${errorMessage}.`;
      }

      return errorMessage;
    },

    /**
     * get the error message for the field
     * @returns {(function(*, *, *): (string|string))|*}
     */
    errorMessage() {
      return (row, key, label) => {
        if (this.isCustomJsonRowValueEmpty(row, key)) {
          return __(":label required", { label: label });
        }

        if (
          key === "output_name" &&
          row.output_type === OUTPUT_TYPE_RETURN_COLUMN
        ) {
          if (
            this.findDuplicateReservedOutputName(row[key], OUTPUT_TYPE_DTMF)
          ) {
            return __(":label is reserved for DTMF JSON path", {
              label: label
            });
          }
          if (
            this.findDuplicateReservedOutputName(row[key], OUTPUT_TYPE_SPEECH)
          ) {
            return __(":label is reserved for SPEECH JSON path", {
              label: label
            });
          }
          if (this.findDuplicateOutputName(row[key])) {
            return __(":label is already in use", { label: label });
          }
        }

        return "";
      };
    },

    /**
     * check a record has been edited if previously created
     */
    showIsInUse() {
      return parseInt(this.isContentInUseCount) > 0;
    },

    ...mapState("folders", {
      selectedFolderId: state => state.selectedFolderId
    })
  },

  methods: {
    ...mapActions("dynamicFormTypes", {
      createDynamicFormType: "createDynamicFormType",
      updateDynamicFormType: "updateDynamicFormType",
      updateContent: "updateDynamicFormType",
      refreshDynamicFormType: "refreshDynamicFormType"
    }),
    ...mapActions("dataStores", {
      getDataStores: "getDataStores",
      resetDataStores: "resetDataStores"
    }),
    //checkInUseCount
    ...mapActions("folders", {
      checkInUseCount: "checkInUseCount"
    }),

    clearDftOutput() {
      this.jsonSchemaPath = "";
      this.jsonSchemaPathOutputName = "";
      this.jsonSchemaPathOutputType = "";
      this.initializeEmptyDftOutputTable();
    },

    initializeEmptyDftOutputTable() {
      this.dftOutput = [
        {
          path: "",
          outputName: "",
          outputType: ""
        }
      ];
    },

    /**
     * path starts with a $ and can be parsed without blowing up, is valid
     * @returns {boolean}
     */
    isValidJsonSchemaPath() {
      try {
        if (!this.jsonSchemaPath) {
          return false;
        }
        if (
          this.jsonSchemaPath !== "" &&
          this.jsonSchemaPath.charAt(0) !== "$"
        ) {
          return false;
        }
        jp.parse(this.jsonSchemaPath);
      } catch {
        return false;
      }
      return true;
    },

    /**
     * output name is a valid one
     */
    isValidJsonSchemaPathOutputName() {
      // empty then invalid
      if (_.isEmpty(this.jsonSchemaPathOutputName)) {
        return false;
      }

      // first check does not match defaults
      if (this.hasJsonSchemaPathDefaultOutputNameConflict()) {
        return false;
      }

      // then check name does not currently exist
      if (this.hasExistingDftOutputName(this.jsonSchemaPathOutputName)) {
        return false;
      }

      return true;
    },

    /**
     * Check conflict output name
     * @returns {boolean}
     */
    hasJsonSchemaPathDefaultOutputNameConflict() {
      // return column type then cant have output name of DTMF or SPEECH, and output name must be different from any other existing
      if (
        this.jsonSchemaPathOutputType === OUTPUT_TYPE_RETURN_COLUMN &&
        (this.jsonSchemaPathOutputName === OUTPUT_TYPE_SPEECH ||
          this.jsonSchemaPathOutputName === OUTPUT_TYPE_DTMF)
      ) {
        return true;
      }

      // can be more than one speech with same output name/type, so no need to check for conflict on that output type

      return false;
    },

    /**
     * check the row is empty
     * @param row
     * @param customJsonRowKey
     */
    isCustomJsonRowValueEmpty(row, customJsonRowKey) {
      return !row[customJsonRowKey];
    },

    /**
     * check return column json paths are not using a reserved output name
     * @param outputName
     * @param reservedName
     * @returns {boolean}
     */
    findDuplicateReservedOutputName(outputName, reservedName) {
      // find all the matches on reserved name
      let reservedNameMatches = _.filter(
        this.contentForm.dft_outputs,
        item =>
          item.output_name === outputName &&
          item.output_name === reservedName &&
          item.output_type === OUTPUT_TYPE_RETURN_COLUMN
      );

      return reservedNameMatches.length > 0;
    },
    /**
     * check output name exists
     * @param outputName
     * @returns {boolean}
     */
    findDuplicateOutputName(outputName) {
      // find all the matches in return columns
      let matches = _.filter(
        this.contentForm.dft_outputs,
        item =>
          item.output_name === outputName &&
          item.output_type === OUTPUT_TYPE_RETURN_COLUMN
      );

      return matches.length > 1;
    },

    handleFormCancel() {
      this.handleCancel();
    },
    /**
     * find dft output by type
     * @param outputType
     * @param outputName
     * @returns {string|*}
     */
    findDftOutput(outputType, outputName) {
      let dftOutputIndex = _.findIndex(
        this.contentForm.dft_outputs,
        item =>
          item.output_type === outputType && item.output_name === outputName
      );
      if (dftOutputIndex !== -1) {
        return this.contentForm.dft_outputs[dftOutputIndex].output_value;
      }
      return "";
    },

    writeDftOutput(value) {
      let self = this;
      return (row, key) => {
        row[key] = value;
        switch (key) {
          case "path":
            self.jsonSchemaPath = value;
            break;

          case "outputName":
            self.jsonSchemaPathOutputName = value;
            break;

          case "outputType":
            self.jsonSchemaPathOutputType = value;

            switch (value) {
              case OUTPUT_TYPE_DTMF:
              case OUTPUT_TYPE_SPEECH:
                this.jsonSchemaPathOutputName = value;
                break;
              default:
                this.jsonSchemaPathOutputName = "";
            }
            break;
        }
      };
    },
    /**
     * find dft output by name
     * @param outputName
     * @returns {string|*}
     */
    hasExistingDftOutputName(outputName) {
      let dftOutputIndex = _.findIndex(
        this.contentForm.dft_outputs,
        item =>
          item.output_name === outputName &&
          item.output_type === OUTPUT_TYPE_RETURN_COLUMN
      );
      if (dftOutputIndex !== -1) {
        return true;
      }
      return false;
    },

    /**
     * check that a json path has been entered for an existing output type
     * @param outputType
     * @param outputValue
     * @returns {boolean}
     */
    hasExistingDftOutputJsonPath(outputType, outputValue) {
      let dftOutputIndex = _.findIndex(
        this.contentForm.dft_outputs,
        item =>
          item.output_type === outputType && item.output_value === outputValue
      );
      if (dftOutputIndex !== -1) {
        return true;
      }

      return false;
    },

    /**
     * add a custom json schema path
     */
    addCustomJsonPath() {
      let addDftOutput = {
        output_id: -1,
        output_value: this.jsonSchemaPath,
        output_type: this.jsonSchemaPathOutputType,
        output_name: this.jsonSchemaPathOutputName
      };
      let dftOutputs = _.cloneDeep(this.contentForm.dft_outputs);
      dftOutputs.push(addDftOutput);

      this.clearDftOutput();
      this.$set(this.contentForm, "dft_outputs", dftOutputs);

      // clear the config after save
      this.clearJsonSchemaPathConfiguration();
      this.triggerValidation(["dft_outputs"]);
    },

    /**
     * clear the Json schema path configuration
     */
    clearJsonSchemaPathConfiguration() {
      this.jsonSchemaPath = "";
      this.jsonSchemaPathOutputType = "";
      this.jsonSchemaPathOutputName = "";
    },

    /**
     * confirm dynamic form type published
     */
    publishFormTypeConfirmation() {
      if (this.contentForm.is_standard) {
        this.publishFormTypeStatus = "publish";
        this.publishFormTypeConfirmed = "published";
      }
      this.$confirm(
        __("Do you want to :status :form_type_name dynamic formtype globally", {
          status: this.publishFormTypeStatus,
          form_type_name: this.contentForm.dft_name
        }),
        __("Warning"),
        {
          confirmButtonText: __("Yes"),
          cancelButtonText: __("No"),
          type: "warning"
        }
      )
        .then(() => {
          this.makeApiCall();
        })
        .catch(() => {
          this.contentForm.is_system = false;
          this.publishFormTypeStatus = "disable";
          this.publishFormTypeConfirmed = "disabled";
          this.$message({
            type: "info",
            message: __("Process canceled")
          });
        });
    },

    /**
     * make api call to confirm publish
     */
    makeApiCall() {
      const process = this.updateDynamicFormType;
      this.contentForm.ac_id = this.selectedAccountId;
      process(this.contentForm)
        .then(data => {
          this.$message({
            type: "success",
            message: __("Dynamic Form Type :dft_name :confirmed successfully", {
              dft_name: this.contentForm.dft_name,
              confirmed: this.publishFormTypeConfirmed
            })
          });
          this.publishFormTypeStatus = "disable";
          this.publishFormTypeConfirmed = "disabled";
          EventBus.$emit("list-changed", data.data);
          this.handleFormCancel();
        })
        .catch(err => {
          this.$message({
            type: "error",
            message: err.response.data.message
          });
        });
    },

    /**
     * confirm save when dynamic form in use on node
     * @param id
     */
    confirmSave(id) {
      if (this.showIsInUse) {
        this.$confirm(
          this.confirmContentInUseMessage,
          this.confirmContentInUseTitle("Dynamic Form Type"),
          {
            confirmButtonText: __("Confirm"),
            cancelButtonText: __("Cancel"),
            type: "warning"
          }
        )
          .then(() => {
            this.submitForm(id);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: __("canceled")
            });
          });
      } else {
        this.submitForm(id);
      }
    },
    /**
     * submit the add edit dynamic from page
     * @param id
     */
    submitForm(id) {
      this.validatingOnSubmit = true;
      this.$refs.contentForm.validate(valid => {
        this.validatingOnSubmit = false;
        if (valid) {
          this.isSubmitting = true;
          const process =
            id === -1 ? this.createDynamicFormType : this.updateDynamicFormType;
          this.contentForm.ac_id = this.selectedAccountId;
          this.contentForm.folder_id = this.selectedFolderId;
          let dftOutputs = _.filter(
            _.cloneDeep(this.contentForm.dft_outputs),
            dftOutput => !_.isEmpty(dftOutput.output_value)
          );
          // filter out any empty dft_output
          this.contentForm.dft_outputs = dftOutputs;

          process(this.contentForm)
            .then(data => {
              this.isSubmitting = false;

              id
                ? this.$message({
                    type: "success",
                    message: __("Dynamic Formtype added Successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Dynamic Formtype updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleFormCancel();
            })
            .catch(err => {
              // console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    /**
     * open move to folder modal
     */
    openMoveToFolderDialog() {
      let content_ids = [this.contentForm.dynamic_form_type_id];
      if (this.checkedContents.length) {
        content_ids = this.checkedContents.map(item => {
          if (item && item.dynamic_form_type_id) {
            return item.dynamic_form_type_id;
          }
        });
      }

      EventBus.$emit("open-move-to-folder-modal", {
        content_ids: [...content_ids],
        content_model: "DynamicFormType"
      });
    },

    /**
     * open check in use modal
     */
    openCheckInUseDialog() {
      EventBus.$emit("open-check-in-use-modal", {
        content_model: "DynamicFormType",
        content_id: this.contentForm.dynamic_form_type_id
      });
    },
    refreshCheckInUse() {
      EventBus.$emit("call-refresh-check-in-use-job", {
        content_model: "DynamicFormType",
        content_id: this.contentForm.dynamic_form_type_id,
        content_name: this.contentForm.dft_name,
        refresh: this.refreshDynamicFormType
      });
    },
    /**
     * handle command selected
     * @param command
     */
    handleAction(command) {
      if (command === "edit") {
        this.handleEdit();
      } else if (command === "move") {
        this.openMoveToFolderDialog();
      } else if (command === "check_in_use") {
        this.openCheckInUseDialog();
      } else if (command === "restore") {
        this.restoreContent(this.contentForm);
      } else if (command === "refresh") {
        this.refreshCheckInUse();
      }
    },
    updateNlpParameters(nlp_param, value, default_value) {
      if (value !== false && value !== 0 && !value) {
        value = default_value;
      }
      this.$set(this.contentForm, nlp_param, value);
    },

    /**
     * update the json schema
     * @param jsonSchema
     */
    updateJsonSchema(jsonSchema) {
      this.$set(this.contentForm, "json_schema", jsonSchema);
      this.triggerValidation(["json_schema"]);
    },

    /**
     * add the json path clicked
     * @param path
     */
    addJsonPath(path) {
      this.dftOutput = [
        {
          path,
          outputName: "",
          outputType: ""
        }
      ];

      this.writeDftOutput(path)(this.dftOutput[0], "path");
      this.writeDftOutput("")(this.dftOutput[0], "outputType");
      this.writeDftOutput("")(this.dftOutput[0], "outputName");
    },

    /**
     * remove a custom json path
     * @param index
     */
    removeJsonPath(index) {
      this.contentForm.dft_outputs.splice(index, 1);
      // this.returnValueDeleted = true;
    },

    /**
     * lock the custom json path output name when output type other than return column
     * @param row
     * @returns {boolean}
     */
    isCustomJsonPathOutputNameLocked(row) {
      if (row.output_type === OUTPUT_TYPE_RETURN_COLUMN) {
        return false;
      }

      return true;
    },

    /**
     * set the output name based on the output type
     * @param row
     */
    setCustomJsonPathOutputName(row) {
      switch (row.output_type) {
        case OUTPUT_TYPE_DTMF:
        case OUTPUT_TYPE_SPEECH:
          row.output_name = row.output_type;
          break;
        default:
          row.output_name = "";
      }
      this.triggerValidation(["dft_outputs"]);
    },

    /**
     * check js function in use
     * @param content_model
     * @param content_id
     * @returns {Promise<unknown>}
     */
    checkDynamicFormTypeInUseCount({ content_model, content_id }) {
      return new Promise((resolve, reject) => {
        this.checkInUseCount({
          content_model,
          content_id
        })
          .then(data => {
            this.isContentInUseCount = data.is_in_use_count;
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    /**
     * trigger validation on dft outputs
     * @param fieldsToTriggerValidation
     */
    triggerValidation(fieldsToTriggerValidation) {
      this.$refs["contentForm"].validateField(fieldsToTriggerValidation);
    },

    /**
     * check to perform unique name check
     * @param newDftName
     * @returns {boolean}
     */
    performUniqueNameCheck(newDftName) {
      let recordId = parseInt(this.id);
      return (
        (recordId >= 0 && newDftName !== this.contentFormInfo.dft_name) ||
        recordId == -1
      );
    }
  },
  watch: {
    formAction: {
      immediate: true,
      handler(newVal, oldVal) {
        this.clearDftOutput();
        if (newVal !== oldVal) {
          if (newVal === "edit") {
            this.checkDynamicFormTypeInUseCount({
              content_model: "DynamicFormType",
              content_id: this.contentForm.dynamic_form_type_id
            });
          } else {
            this.isContentInUseCount = 0;
          }
        }
      }
    },
    contentUpdated: {
      handler() {
        this.$refs["contentForm"].validateField(["dft_outputs"]);
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-form-item__content {
  margin-left: 0 !important;
}

.custom-formtype-icon {
  height: 40px;
  width: 50px;
  background-size: cover;
  padding-top: 5px;
  margin-right: 10px;
  margin-top: 32px;
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
.customJsonPathsTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.customJsonPathsTable {
  ::v-deep .no-red-highlight .el-input__inner,
  ::v-deep .no-red-highlight .el-textarea__inner,
  ::v-deep .empty-row .el-input__inner,
  ::v-deep .empty-row .el-textarea__inner {
    border-color: $--border-color-base !important;

    &:focus {
      border-color: $--color-text-regular !important;
    }
  }
}

.customJsonPathsTable ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.custom-json-path-field {
  display: flex;
  width: 100%;
  gap: 2.5%;
  align-items: flex-end;

  ::v-deep .el-form-item__label {
    font-size: 14px;
    word-break: break-word;
  }

  & .custom-json-path-field-item {
    padding-bottom: 15px;
  }

  & .custom-json-path-field-item--jsonpath {
    flex: 1 0 30%;
  }

  & .custom-json-path-field-item--outputtype {
    flex: 1 0 25%;
  }

  & .custom-json-path-field-item--outputname {
    flex: 1 0 25%;
  }

  & .custom-json-path-field-item--addjsonpath,
  & .custom-json-path-field-item--action {
    flex: 1 0 7%;
  }

  & .custom-json-path-field-item--msg,
  & .custom-json-path-field-item--action {
    padding-bottom: 25px;
  }

  & .custom-json-path-field-item--action {
    text-align: right;
    cursor: pointer;
    opacity: 0;
  }

  & .custom-json-path-field-item--msg {
    min-width: 68px;
    font-size: 0.75rem;
    color: $--color-success;
    margin-left: 10px;
  }

  &:hover {
    .custom-json-path-field-item--action {
      opacity: 1;
    }
  }
}

.el-table.button-rules {
  ::v-deep .el-table__row + .el-table__row {
    .el-form-item__label {
      display: none !important;
    }

    .custom-json-path-field {
      padding-top: 10px;
    }
  }
}

.button-rules ::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.customJsonPathsTable ::v-deep .el-table::before {
  background-color: white !important;
}

.customJsonPathsTable ::v-deep .el-table__body-wrapper {
  margin-top: 10px;
}

.draggable .action-icon {
  cursor: grab;
}

.grabbing {
  cursor: grabbing !important;
}

.grabbing * {
  cursor: grabbing !important;
}

/* hack to remove bottom border appearing on form fields section */
.el-table::before {
  height: 0 !important;
}
</style>
