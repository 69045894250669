var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-component",staticStyle:{"width":"100%"},on:{"click":_vm.handleClearSelection}},[_c('el-button',{staticClass:"createBtn",attrs:{"disabled":!_vm.can('content.' + _vm.additionalValidateRoute + '.write')},on:{"click":function($event){$event.stopPropagation();return _vm.createContentItem.apply(null, arguments)}}},[_vm._v("Create Dynamic Formtype")]),_c('div',{staticStyle:{"width":"100%"}},[_c('el-main',{staticStyle:{"margin-top":"136px"}},[_c('el-form',{staticClass:"form-container"},[_c('PaginationToolbar',{attrs:{"sort-by-options":_vm.sortByOptions,"content-api":_vm.contentAPI},on:{"success":function($event){return _vm.$emit('replaceContent', $event)}}})],1),_c('el-scrollbar',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"native":true}},[_c('div',{staticClass:"contentCardsContainer"},[(_vm.dynamicFormTypes.length)?_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.can('content.' + _vm.additionalValidateRoute + '.read')),expression:"can('content.' + additionalValidateRoute + '.read')"}],staticClass:"list-cards",attrs:{"type":"flex","justify":"start"}},_vm._l((_vm.dynamicFormTypes),function(dynamicFormType,index){return _c('ContentCard',{key:'_content_dynamic_form_type_' + index,attrs:{"draggable-data":{
                content_id: _vm.selectedItems.length
                  ? _vm.selectedIds
                  : [dynamicFormType[_vm.primaryKey]],
                content_type: _vm.contentType
              },"allow-multi-select":true,"card-class":{ selected: _vm.selectedItemIndex === index },"icon":"icon-custom-form.svg","card-text":dynamicFormType.dft_name,"total-items-selected":_vm.selectedItems.length},on:{"delete":function($event){return _vm.handleDelete(
                  dynamicFormType,
                  'DynamicFormType',
                  dynamicFormType.dynamic_form_type_id
                )},"select":function($event){return _vm.handleSelect(index, dynamicFormType)},"edit":function($event){return _vm.handleEdit(index, dynamicFormType)},"checked-content":function($event){_vm.handleSelectMultiple($event)(dynamicFormType)}}})}),1):(!_vm.loading)?_c('el-row',{staticStyle:{"padding-top":"100px"},attrs:{"type":"flex","justify":"center"}},[_vm._v(" No Dynamic Forms ")]):_vm._e()],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }