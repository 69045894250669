<template>
  <div>
    <div
      class="web-service-container"
      :style="{
        '--btn-color-normal': btnColorScheme.normal,
        '--btn-color-disabled': btnColorScheme.disabled,
        '--btn-color-hover': btnColorScheme.hover
      }"
    >
      <el-row :gutter="10" type="flex">
        <el-col class="json-result-container">
          <div style="position: absolute; right: 70px; top: 5px ; z-index: 2">
            <el-popover
              v-if="showSetBtn"
              placement="top-end"
              trigger="hover"
              :content="__('Return to preview without saving changes')"
            >
              <div slot="reference">
                <el-button
                  @click="closeEditor(true)"
                  type="primary"
                  id="closeJsonSchemaMapperEditor"
                  icon="el-icon-close"
                  circle
                ></el-button>
              </div>
            </el-popover>
          </div>
          <div style="position: absolute; right: 25px; top: 5px ; z-index: 2">
            <el-popover
              v-if="showEditorBtn"
              placement="top-end"
              trigger="hover"
              :content="__('Edit Json Schema')"
            >
              <div slot="reference">
                <el-button
                  @click="editJsonSchema"
                  type="primary"
                  icon="el-icon-edit"
                  id="openJsonSchemaMapperEditor"
                  circle
                ></el-button>
              </div>
            </el-popover>

            <el-popover
              v-if="showSetBtn && !editorError"
              placement="top-start"
              trigger="hover"
              :content="baseSaveChangesMessage"
            >
              <div slot="reference">
                <el-button
                  @click="closeEditor(false)"
                  type="primary"
                  icon="el-icon-check"
                  id="saveJsonSchemaMapperEditor"
                  circle
                ></el-button>
              </div>
            </el-popover>
          </div>
          <el-scrollbar>
            <div class="json-box" style="max-height: 300px">
              <vue-json-pretty
                v-if="!displayEditor"
                :data="localJsonSchema"
                :deep="10"
                rootPath="$"
                :highlightMouseoverNode="true"
                :highlightSelectedNode="true"
                style="line-height: 20px"
                @node-click="handleJSONClick"
              >
              </vue-json-pretty>
              <v-json-editor
                v-else
                v-model="localJsonSchema"
                :options="editorOptions"
                @error="editorError = true"
                :plus="false"
                height="300px"
              ></v-json-editor>
            </div>
          </el-scrollbar>
        </el-col>
      </el-row>
      <el-row :gutter="10" type="flex"> </el-row>
    </div>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import VJsonEditor from "v-jsoneditor";
import _ from "lodash";
import {
  primary,
  primaryDisabled,
  primaryHover
} from "@/styles/element-variables.scss";

import { jsonPathValue } from "@/utils/canvas";
import { mapGetters } from "vuex";

//import JsonPath from "@/mixins/JsonPath";

import { prettifyJsonPath } from "@/utils/transformers";
import {
  convertDotNotationToBracketNotation
  // getSubKeyObject
} from "@/utils/collection";

export default {
  name: "json-schema-mapper",
  components: {
    VueJsonPretty,
    VJsonEditor
  },
  // mixins: [JsonPath],
  props: {
    jsonSchema: {
      required: false,
      type: String,
      default: "{}"
    },
    jsonSchemaEditable: {
      required: false,
      type: Boolean,
      default: false
    },
    showEditBtn: {
      required: false,
      type: Boolean,
      default: true
    },
    showPreviewButton: {
      required: false,
      type: Boolean,
      default: true
    },
    enablePreviewButton: {
      required: false,
      default: true,
      type: Boolean
    },
    btnColorScheme: {
      required: false,
      type: Object,
      default: () => ({
        normal: primary,
        disabled: primaryDisabled,
        hover: primaryHover
      })
    }
  },

  data() {
    return {
      displayEditor: false,
      localJsonSchema: {},
      // JSONPath: "",
      jsonPathResult: "",
      editorError: false,
      jsonPathValue,
      evaluatingJsonPath: false,
      editJsonPath: false,
      highlightedIndex: null,
      prettify: prettifyJsonPath
    };
  },
  created() {
    this.resetLocalJsonSchema();
  },

  computed: {
    ...mapGetters("variables", {
      variables: "variables"
    }),

    getOptionsInitiators() {
      return ["{{", "[["];
    },

    isVisible() {
      return index => {
        return index === this.highlightedIndex;
      };
    },

    /**
     * save the changes message. check if changes will affect json path selected and inform user if so
     * @returns {string}
     */
    baseSaveChangesMessage() {
      return __("Save changes and return to preview.");
    },

    /**
     * show the editor button
     * @returns {boolean}
     */
    showEditorBtn() {
      return this.jsonSchemaEditable && this.showEditBtn && !this.displayEditor;
    },
    /**
     * show the save/discard buttons
     * @returns {false|boolean}
     */
    showSetBtn() {
      return this.jsonSchemaEditable && this.showEditBtn && this.displayEditor;
    },

    /**
     * editor options
     * @returns {{mode: string, mainMenuBar: boolean}}
     */
    editorOptions() {
      return {
        mainMenuBar: false,
        mode: "code"
      };
    }
  },

  methods: {
    /**
     * reset the local json schema to the prop
     */
    resetLocalJsonSchema() {
      this.localJsonSchema = {};

      let parsedJson = JSON.parse(this.jsonSchema);
      if (!_.isEmpty(parsedJson)) {
        this.localJsonSchema = parsedJson;
      }
    },

    setShowData(val) {
      this.showData = val;
      this.editJsonPath = false;
      this.resetJsonPath();
    },

    updateHoveringIndex(index) {
      this.highlightedIndex = index;
    },

    editJsonSchema() {
      this.displayEditor = true;
    },

    /**
     * close the editor and save to parent
     */
    closeEditor(discardChanges = false) {
      this.displayEditor = false;

      // reset local json schema
      if (discardChanges) {
        this.resetLocalJsonSchema();
      } else {
        this.$emit(
          "changeJsonSchema",
          JSON.stringify(_.cloneDeep(this.localJsonSchema))
        );
      }
    },
    /**
     * get the element clicked on
     * @param node
     */
    handleJSONClick(node) {
      if (node.path === "$") {
        this.$message({
          type: "info",
          // eslint-disable-next-line
          message: __("the entire response is automatically associated with the node")
        });
      } else {
        this.$emit(
          "sampleJsonSchemaClicked",
          convertDotNotationToBracketNotation(node.path)
        );
      }
    }
  },
  watch: {
    localJsonSchema: {
      deep: true,
      immediate: true,
      handler() {
        this.editorError = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/element-variables.scss";
@import "~@/styles/node-palette.scss";

.json-box {
  ::v-deep .jsoneditor {
    border: none;
    line-height: 20px;

    .ace-jsoneditor.ace_editor {
      line-height: 20px;
    }
  }
}

.json-result-container {
  position: relative;
  margin-bottom: 10px;
}

.json-path-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  line-height: 28px;
  word-break: normal;

  .preview-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.json-path-part {
  margin-top: 5px;
  padding: 3px 5px;
  display: flex;
  align-items: center;

  &:focus-within,
  &.highlight {
    background: #e3e3e3;
    border-radius: 6px;
  }

  .json-part-content {
    margin: 0 2px;
  }

  ::v-deep .editableContent {
    min-width: 80px;
    border-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 0 10px;
    overflow: auto;

    &:focus {
      border: 1px solid black;
    }
  }
}

.web-service-container {
  ::v-deep .el-button--primary {
    background-color: var(--btn-color-normal) !important;
    border: none;
    color: $--color-white !important;

    &:hover {
      background-color: var(--btn-color-hover) !important;
      border-color: var(--btn-color-hover) !important;
    }

    &.is-disabled {
      background-color: var(--btn-color-disabled) !important;
      border-color: var(--btn-color-disabled) !important;
    }
  }

  ::v-deep .el-checkbox__input {
    &.is-focus {
      & .el-checkbox__inner {
        border-color: var(--btn-color-normal) !important;
      }
    }

    & .el-checkbox__inner {
      &:hover,
      &:active {
        border-color: var(--btn-color-normal) !important;
      }
    }

    &.is-checked,
    &.is-checked.is-focus {
      & .el-checkbox__inner {
        background-color: var(--btn-color-normal) !important;
        border-color: var(--btn-color-normal) !important;
      }

      & + .el-checkbox__label {
        color: #111111;
      }
    }
  }

  .response-button-group {
    padding-bottom: 5px;
    ::v-deep .active {
      background-color: var(--btn-color-hover) !important;
      border-color: var(--btn-color-hover) !important;
    }
  }
}

.json-path-result-row {
  background-color: black;
  color: white;
  font-size: 0.75rem;
  height: 25px;
  margin-top: 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.json-path-result-container {
  display: flex;
  justify-content: flex-end;
}

.json-path-result {
  padding-right: 20px;
}
</style>
