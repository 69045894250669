<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "DynamicFormTypesIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedItemType: null,
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("dynamicFormTypes", {
      contents: state => state.dynamicformtypes
    }),
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    })
  },

  methods: {
    initContent() {
      return {
        ac_id: "",
        dft_name: "",
        dft_description: "",
        json_schema: "",
        is_archived: 0,
        speech_complete_timeout: 1000,
        speech_incomplete_timeout: 20000,
        no_input_timeout: 5000,
        speech_start_timeout: 50,
        inter_result_timeout: 0,
        barge_in_sensitivity: 0.5,
        auto_punctuation: false,
        profanity_filter: false,
        single_utterance: true,
        dft_outputs: []
      };
    }
  },

  mounted() {
    this.contentType = "DynamicFormType";
    this.primaryKey = "dynamic_form_type_id";
  }
};
</script>

<style lang="scss" scoped></style>
